import _ring_buffer from "./ring_buffer";
var exports = {};
var RingBuffer = _ring_buffer;

var Pledge = function () {
  this._complete = false;
  this._callbacks = new RingBuffer(Pledge.QUEUE_SIZE);
};

Pledge.QUEUE_SIZE = 4;

Pledge.all = function (list) {
  var pledge = new Pledge(),
      pending = list.length,
      n = pending;
  if (pending === 0) pledge.done();

  while (n--) list[n].then(function () {
    pending -= 1;
    if (pending === 0) pledge.done();
  });

  return pledge;
};

Pledge.prototype.then = function (callback) {
  if (this._complete) callback();else this._callbacks.push(callback);
};

Pledge.prototype.done = function () {
  this._complete = true;
  var callbacks = this._callbacks,
      callback;

  while (callback = callbacks.shift()) callback();
};

exports = Pledge;
export default exports;